import { createRouter } from "@uxf/router";

export interface RouteList {
    // main-zone
    "main-zone/index": null;
    "main-zone/intro": { redirect?: string };
    "main-zone/sitemap-xml": null;
    "main-zone/location": { date?: string };
    "main-zone/restaurant-search": { date?: string };
    "main-zone/restaurant-menu": { restaurant: number; date: string; distance?: number };
    "main-zone/restaurant-menu-tomorrow": { restaurant: number };
    "main-zone/restaurant-detail": { restaurant: number; distance?: number };
    "main-zone/cart": { refetch?: boolean };
    "main-zone/order-detail": { restaurant: number; date: string };
    "main-zone/create-payment": null;
    "main-zone/payment-finished": null;
    "main-zone/payment-failed": null;

    // user-zone
    "user-zone/login": { redirect?: string };
    "user-zone/register": { redirect?: string };
    "user-zone/logout": null;
    "user-zone/change-password": null;
    "user-zone/renew-password": { token: string };
    "user-zone/forgotten-password": null;
    "user-zone/credit-history": null;
    "user-zone/my-meals-history": null;
    "user-zone/my-receipts": null;
    "user-zone/receipt-detail": { id: number };
}

export default createRouter<RouteList>({
    // main-zone
    "main-zone/index": "/",
    "main-zone/intro": "/vitejte",
    "main-zone/sitemap-xml": "/sitemap.xml",
    "main-zone/location": "/lokace",
    "main-zone/restaurant-search": "/najit-restauraci",
    "main-zone/restaurant-menu": "/restaurace/[restaurant]/menu/[date]",
    "main-zone/restaurant-menu-tomorrow": "/restaurace/[restaurant]/menu",
    "main-zone/restaurant-detail": "/restaurace/[restaurant]",
    "main-zone/cart": "/kosik",
    "main-zone/order-detail": "/objednavka/[restaurant]/[date]",
    "main-zone/create-payment": "/objednavka/platba",
    "main-zone/payment-finished": "/objednavka/dokonceno",
    "main-zone/payment-failed": "/objednavka/neuspesne",

    // user-zone
    "user-zone/login": "/uzivatel/prihlaseni",
    "user-zone/register": "/uzivatel/registrace",
    "user-zone/logout": "/uzivatel/odhlaseni",
    "user-zone/change-password": "/uzivatel/zmena-hesla",
    "user-zone/renew-password": "/renew-password/[token]",
    "user-zone/forgotten-password": "/uzivatel/zapomenute-heslo",
    "user-zone/credit-history": "/uzivatel/historie-kreditu",
    "user-zone/my-meals-history": "/uzivatel/moje-jidla",
    "user-zone/my-receipts": "/uzivatel/moje-uctenky",
    "user-zone/receipt-detail": "/uzivatel/moje-uctenky/[id]",
});
